//@ts-check
import {addEventListener, removeEventListener, pagehide} from '../utils/windowEvents.js';

const VISIBILITY  = 'visibilitychange';

/**
 * Get visibility attributes
 * @param {import('../utils/utils.js').State} state
 * @return {Promise<{entryType: 'visibility', type: string, startTime: number}>}
 */
export default function hidden([window]) {
    return new Promise(resolve => {
        if (isHidden()) {
            return done(VISIBILITY, 0);
        }

        addEventListener(window, VISIBILITY, visibilitychange, false);
        function visibilitychange({timeStamp}) {
            if (isHidden()) {
                removeEventListener(document, VISIBILITY, visibilitychange, false);
                done(VISIBILITY, timeStamp);
            }
        }

        pagehide(window).then(({startTime}) => done('pagehide', startTime));

        /**
         * @param {string} type 
         * @param {number} startTime 
         */
        function done(type, startTime) {
            resolve({
                entryType: 'visibility',
                type,
                startTime
            });
        }

        function isHidden() {
            const {document} = window;
            const {visibilityState} = document;
            return typeof visibilityState === 'undefined' ? document.hidden : visibilityState === 'hidden';
        }
    });
}
